<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Add Object</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="form_data.page_id"
                :error-messages="form_error.page_id"
                :items="pages"
                v-on:change="getPositionList(form_data.page_id)"
                item-text="display_name"
                item-value="page_id"
                label="Select Page"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="form_data.position_id"
                :error-messages="form_error.position_id"
                :items="positions"
                item-text="name"
                item-value="page_position_id"
                label="Object Position"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.object_file"
                :error-messages="form_error.object_file"
                accept="image/*, video/*, application/pdf"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.link"
                dense
                label="Link"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="addObject"
            :loading="loading"
            :disabled="loading"
          >submit</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/object">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>

import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      pages: [],
      positions: [],
      form_data: {},
      form_error: {},
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    addObject() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.object_file) {
        formData.append("object_file", this.form_data.object_file);
      }
      this.$axios.post(`admin/object/add`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/object");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getPageList() {
      let _self = this;
      this.$axios.get(`admin/page/list`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.pages = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getPositionList(page_id) {
      let _self = this;
      this.$axios.get(`admin/position/list?page_id=` + page_id)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.positions = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getPageList();
  },
};
</script>